<template>
  <div>
    <!-- Search -->
    <v-text-field
      v-model="search"
      class="mt-2"
      :label="$t('labels.leads.lead-search-valuation')"
      hide-details
      single-line
      clearable
      solo
      flat
      dense
      outlined
      append-icon="mdi-magnify"
      @click:clear="reset"
    />
    <v-slide-y-transition>
      <div>
        <!-- Progress Bar -->
        <v-progress-linear
          v-if="state==='loading'"
          color="primary"
          indeterminate
          rounded
          height="8"
          class="mt-4"
        />

        <!-- Leads -->
        <div
          v-if="state==='done' && leads.length > 0"
          class="mt-2 results"
        >
          <v-radio-group v-model="selectedLeadId">
            <v-radio
              v-for="lead in leads"
              :key="lead.id"
              color="primary"
              class="mb-1 pa-2 suggestion"
              :value="lead.id"
            >
              <template v-slot:label>
                <div class="flex-column d-flex">
                  <span>
                    <b>{{ lead.firstname }} {{ lead.lastname }}</b>
                  </span>
                  <span>{{ lead.email }}</span>
                </div>
              </template>
            </v-radio>
          </v-radio-group>
        </div>

        <p
          v-if="state==='done' && leads.length<=0"
          class="mt-4 text-center pa-2 suggestion"
        >
          {{ $t('valuation.lead-search.no-data') }}
        </p>
      </div>
    </v-slide-y-transition>
    <p
      class="mt-2"
    >
      {{ $t('valuation.lead-search.no-contact') }}
    </p>
    <!-- Create new Lead -->
    <create-lead-dialog @created="handleCreated">
      <template v-slot:activator>
        <v-btn
          class="mt-n4"
          color="primary"
          small
          primary
        >
          {{ $t('buttons.valuation.lead-search') }}
        </v-btn>
      </template>
    </create-lead-dialog>
  </div>
</template>

<script>
import GET_LEAD_SUGGESTIONS from '../queries/getLeadSuggestions.gql'

export default {
  components: {
    CreateLeadDialog: () => import('@/modules/leads/CreateLeadDialog')
  },
  data () {
    return {
      search: '',
      leads: [],
      selectedLeadId: '',
      spinnerTimeout: null,
      debounceTimeout: null,
      state: 'idle',
      fetchLeadSuggestions: false
    }
  },
  apollo: {
    leads: {
      query: GET_LEAD_SUGGESTIONS,
      variables () {
        return {
          input: { search: this.search || '' }
        }
      },
      skip () {
        return !this.fetchLeadSuggestions || !this.search || this.search.length < 2
      },
      update ({ leads }) {
        this.state = 'done'
        this.fetchLeadSuggestions = false
        return leads
      }
    }
  },
  watch: {
    search () {
      if (!this.search) {
        this.setIdleState()
      }

      clearTimeout(this.debounceTimeout)

      this.debounceTimeout = setTimeout(() => {
        if (this.search && this.search.length > 2) {
          this.setIdleState()
          this.state = 'loading'
        }
        // display progressbar
        this.spinnerTimeout = setTimeout(() => {
          this.fetchLeadSuggestions = true
        }, 1000)
      }, 500)
    },

    selectedLeadId () {
      this.$emit('select', this.selectedLeadId)
    }

  },
  methods: {
    setIdleState () {
      this.leads = []
      this.selectedLeadId = ''
      this.state = 'idle'
    },

    reset () {
      this.search = ''
      this.state = 'idle'
      this.leads = []
      this.selectedLeadId = ''
      clearTimeout(this.debounceTimeout)
      clearTimeout(this.spinnerTimeout)
    },

    handleCreated ({ id }) {
      this.$emit('created', id)
    }
  }
}
</script>

<style scoped>
.results{
  max-height:300px;
  overflow: scroll;
}

.suggestion{
  border: 1px solid lightgray;
  border-radius: 5px;
}
</style>
