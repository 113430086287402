<template>
  <div>
    <v-text-field
      ref="addressField"
      v-model="input"
      name="address"
      type="search"
      :label="$t('valuation.labels.address')"
      persistent-placeholder
      :rules="fieldRules"
      validate-on-blur
      outlined
      dense
      required
      @change="handleInputChange"
    />
    <div
      v-if="incompleteAddress"
      class="text-caption"
    >
      {{ $t('valuation.address-field.incomplete-address') }}
    </div>
  </div>
</template>

<script>
import * as Sentry from '@sentry/vue'
import mapMixin from '@/mixins/map'

const getInitialAddress = () => ({
  zip: '',
  city: '',
  street: '',
  houseNumber: '',
  countryCode: ''
})

export default {
  mixins: [mapMixin],

  props: {
    countryCode: {
      type: String,
      required: true
    },
    value: {
      type: Object,
      default: null
    }
  },

  data: () => ({
    model: null,
    input: '',
    incompleteAddress: false,
    fieldRules: []
  }),

  computed: {
    rules () {
      return [
        () => !this.incompleteAddress || this.$t('valuation.address-field.rules.incomplete', { field: this.incompleteAddress }),
        () => this.model !== null || this.$t('valuation.address-field.rules.invalid')
      ]
    }
  },

  watch: {
    model (value) {
      if (value) {
        this.$emit('input', this.model)
      }
    },
    value (value) {
      if (!value) {
        this.reset()
      }
    }
  },

  async mounted () {
    this.loadGoogleMaps()
    await this.googleMapsLoaded()

    this.addressFieldRef = this.$refs.addressField
    this.inputRef = this.addressFieldRef.$refs.input
    this.enableSelectionOnEnter(this.inputRef)

    const options = {
      types: ['address'],
      fields: ['address_components', 'geometry.location'],
      componentRestrictions: {
        country: [this.countryCode]
      }
    }

    this.autocomplete = new window.google.maps.places.Autocomplete(this.inputRef, options)
    this.autocomplete.addListener('place_changed', this.updatePlace)
  },

  methods: {
    handleInputChange (input) {
      if (!input) {
        this.model = null
        this.input = ''
        this.incompleteAddress = false
        this.$emit('input', this.model)
      }
    },
    // Hack to handle propper rules validation
    // @see https://rmirabelle.medium.com/fixing-vuetifys-form-validation-3a5781ea43fe
    validateFieldInput () {
      this.fieldRules = this.rules
      this.addressFieldRef.validate()
    },
    updatePlace () {
      this.incompleteAddress = null
      this.fieldRules = []

      const place = this.autocomplete.getPlace()

      // Reset if place changed to null
      if (!place) {
        this.model = null
        this.input = ''
        return
      }

      this.input = this.inputRef.value

      if (!place.geometry) {
        this.incompleteAddress = this.$t('labels.coordinates')
        this.logError(`Missing "geometry" for place. API response: ${JSON.stringify(place)}`)
        this.validateFieldInput()
        return
      }

      const address = this.mapAddress(place.address_components)

      if (!address.zip) {
        this.incompleteAddress = this.$t('labels.zip')
        this.logError(`Missing "zip" for place. API response: ${JSON.stringify(place)}`)
        this.validateFieldInput()
        return
      }

      if (!address.city) {
        this.incompleteAddress = this.$t('labels.city')
        this.logError(`Missing "city" for place. API response: ${JSON.stringify(place)}`)
        this.validateFieldInput()
        return
      }

      this.model = {
        address,
        coordinates: place.geometry.location.toJSON() // { lat, lng }
      }
    },

    mapAddress (addressComponents) {
      const fieldsToExtract = {
        postal_code: {
          pickValueFrom: 'short_name',
          storeAs: 'zip'
        },
        locality: {
          pickValueFrom: 'long_name',
          storeAs: 'city'
        },
        route: {
          pickValueFrom: 'long_name',
          storeAs: 'street'
        },
        street_number: {
          pickValueFrom: 'long_name',
          storeAs: 'houseNumber'
        },
        country: {
          pickValueFrom: 'short_name',
          storeAs: 'countryCode',
          formatter: value => value.toLowerCase()
        }
      }

      return addressComponents.reduce((address, component) => {
        const type = component.types[0]
        const fieldMapping = fieldsToExtract[type]

        if (fieldMapping) {
          const { pickValueFrom: sourceKey, storeAs: targetKey, formatter = val => val } = fieldMapping
          address[targetKey] = formatter(component[sourceKey])
        }

        return address
      }, getInitialAddress())
    },

    reset () {
      this.autocomplete.set('place', null)
    },

    // Hack to select first Element in Suggestion on Enter
    // https://stackoverflow.com/questions/7865446/google-maps-places-api-v3-autocomplete-select-first-option-on-enter
    enableSelectionOnEnter (input) {
      const _addEventListener = input.addEventListener
      const addEventListenerWrapper = (type, listener) => {
        if (type === 'keydown') {
          const _listener = listener
          listener = event => {
            const suggestionSelected = document.getElementsByClassName('pac-item-selected').length
            if (event.key === 'Enter') {
              event.preventDefault()
              if (!suggestionSelected) {
                const e = new KeyboardEvent('keydown', { key: 'ArrowDown', code: 'ArrowDown', keyCode: 40 })
                _listener.apply(input, [e])
              }
            }
            _listener.apply(input, [event])
          }
        }
        _addEventListener.apply(input, [type, listener])
      }
      input.addEventListener = addEventListenerWrapper
    },

    logError (errorMessage) {
      Sentry.addBreadcrumb({
        category: 'google-places-api',
        description: 'Invalid response from Google Places API'
      })
      Sentry.captureException(errorMessage)
    }
  }
}
</script>
