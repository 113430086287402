<template>
  <div>
    <ValuationForm
      @created="handleValuationCreated"
      @reset="handleFormReset"
    />

    <v-slide-y-transition>
      <v-card
        v-if="currentValuation"
        class="mt-4"
        :class="{'mb-n6':currentValuation.results}"
        outlined
      >
        <v-container
          v-if="valuationInProgress"
          style="height: 200px;"
        >
          <v-row
            class="fill-height"
            align-content="center"
            justify="center"
          >
            <v-col
              class="text-center text-subtitle-1"
              cols="12"
            >
              {{ $t('alerts.valuation.in-progress') }}<br>
            </v-col>
            <v-col cols="6">
              <v-progress-linear
                color="primary"
                indeterminate
                rounded
                height="8"
              />
            </v-col>
          </v-row>
        </v-container>

        <v-alert
          v-if="errorFetchingValuation"
          type="error"
          class="mb-0"
          text
        >
          {{ $t('alerts.valuation.error') }}
        </v-alert>
      </v-card>
    </v-slide-y-transition>

    <ValuationHistory
      class="mt-5"
      :current-valuation="currentValuation"
    />
  </div>
</template>

<script>
import ValuationForm from './ValuationForm.vue'
import GET_VALUATION from '@/queries/GetValuation.gql'
import ValuationHistory from './ValuationHistory.vue'
import { status as ValuationStatus } from '@/lib/valuation'

export default {
  components: {
    ValuationForm,
    ValuationHistory
  },

  data: () => ({
    currentValuation: null,
    errorFetchingValuation: false,
    ValuationStatus
  }),

  computed: {
    valuationInProgress () {
      return ['PENDING', 'PROCESSING'].includes(this.currentValuation?.status)
    }
  },

  apollo: {
    currentValuation: {
      query: GET_VALUATION,
      variables () {
        return {
          valuationId: this.currentValuation.id
        }
      },
      skip () {
        // Polling should be stopped on error or when the valuation is completed
        return this.errorFetchingValuation || !this.currentValuation || !this.valuationInProgress
      },
      update (data) {
        return data.valuation
      },
      error () {
        this.errorFetchingValuation = true
      },
      pollInterval: 1000
    }
  },

  methods: {
    handleValuationCreated (valuation) {
      this.currentValuation = valuation
      this.errorFetchingValuation = false
    },

    handleFormReset () {
      this.currentValuation = null
      this.errorFetchingValuation = false
    }
  }
}
</script>
